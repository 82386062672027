// Main scripts test

document.addEventListener("DOMContentLoaded", function(){
  window.addEventListener('scroll', function() {
    if (window.scrollY > 100) {
      document.getElementById('site-navbar').classList.add('navbar-sticky');
    } else {
      document.getElementById('site-navbar').classList.remove('navbar-sticky');
    }
  });
});
